export default {
    namespaced: true,
    state: {
      codeMapping : {
        5: '영아1,2부',        
        7: '유아부',
        8: '새싹부',
        9: '유치부',
        10: '열매1부',
        11: '열매2부',
        12: '유년부',
        13: '초등부',
        14: '소년부',
        15: '키즈프레이즈',
        1: '청소년1부',
        4: '청소년1부+',
        2: '청소년2부',
        3: '청소년3부',
        16: '소망1부',
        17: '소망2부'
      }
    },
    getters: {
      getCodeName: (state) => (codeValue) => {
        return state.codeMapping[codeValue] || '';
      }
    }
  };
  